.total-gmail-link-part{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 20px 0px;
    gap: 20px;
    width: 360px;
    height: auto;
    background: #FFFFFF;
    margin: auto;
  
    box-sizing: border-box;
   
}
.gmail-link-header-img{

    width: 100%;
    height: 120px;
    box-sizing: border-box;
    margin: auto;
}
.gmail-link-header-img img{
  
 width: 100%;
 height: 120px;
}

.Faq-title {
    margin-bottom: 10px;
  
}

.gmail-faq{
    margin: auto;
 }

 .gmail-faq>div{
    padding: 10px;
    box-sizing: border-box;
    margin: auto;
 }




.link-gmail-button-total{
    width: 100%;
    height: 48px;
    

}
.link-gmail-button-total button{
    width: 100%;
    height: 100%;
    background: #00C5B3;
    border-radius: 8px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    cursor: pointer;
    border: none;

}
.gmail-status-bar{

    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.total-gmail-body{
display: flex;
flex-direction: column;

align-items: center;
padding: 36px 12px;
gap: 36px;
width: 100%;
height: 590px;
background: #EAFCFF;
flex: none;
order: 2;
flex-grow: 0;
box-sizing: border-box;
}
.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-weight: 400;
    font-family: 'Soleil';
    font-style: normal;
}
.flex-center img{
width:64px;
height:64px;
border-radius:16px;
}
.flex-center-text-container>div:first-child{
font-size: 18px;
line-height: 18px;
color: #55606F;
}
.flex-center-text-container>div:nth-child(2){
width: 304px;
font-size: 15px;
line-height: 20px;
text-align: center;
color: #98A2B3;
margin-top: 4px;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

}
.Faq-style{
    width: "328px";
    margin:"auto";
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(110, 120, 130, 0.05);
}