.cursor{
    cursor: pointer;
}

.total-faq{
width: 100%;
padding: 10px;
margin: auto;
background: #FFFFFF;  
box-shadow: 0px 8px 16px rgba(110, 120, 130, 0.05);
border-radius: 8px;
box-sizing: border-box;
font-family: 'Soleil';
font-style: normal;
font-weight: 400;
line-height: 20px;
color: #55606F;
}

.faq-answer{
    font-size: 14px;
    margin-top: 10px;
}
.faq-flex-dropdown{
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 18px;
}
.faq-flex-dropdown>div:first-child{
    width: 95%;
}
.gmail-faq1,.gmail-faq2>div:first-child{
    font-size: 10px;
    line-height: 18px;
}
