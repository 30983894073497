.total-finance-part {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 20px;
    gap: 20px;
    width: 360px;
    height: auto;
    background: #FFFFFF;
    margin: auto;
    box-sizing: border-box;
    height: auto;


  
}

.total-finance-part>div {
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    height: auto;
}

.finance-heading {
    display: grid;
    height: auto;
    gap: 12px;
    width: 100%;
    margin-bottom: 20px;
    
}

.finance-heading:first-child h2 {
    height: auto;
    font-family: 'Soleil';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22.4px;
    text-align: center;
    color: #55606F;
    margin: auto;
    box-sizing: border-box;
    padding: 0px 30px;
    
    
}

.finance-heading-last {
    height: 18px;
    font-family: 'Soleil';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    color: #56606c;
    margin: auto;

}

.finance-survey-box {
    padding: 10px;
    height: auto;
    font-family: 'Soleil';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #6a717c;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
   text-align: left;
   margin-bottom: 20px;
  
   

}

.finance-form-section {

    margin: 0px auto  ;
    width: 304px;
    

}

.finance-form-section select {
    width: 300px;
    margin: auto;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #E8EBEF;
    border-radius: 8px;
    outline: none;
}
.credit-form-interest select{
    width: 300px;
    margin: auto;
    background: #FFFFFF;
    height: 40px;
    border: 1px solid #E8EBEF;
    border-radius: 8px;
    outline: none;
    border: 1px solid red;
}
.finance-form-section input {
    width: 300px;
    margin: auto;
    background: #FFFFFF;
    height: 30px;
    border: 1px solid #E8EBEF;
    border-radius: 8px;
    outline: none;
   box-sizing: border-box;
   padding-left: 20px;
}




.finance-submit-form {
    width: 304px;
    height: 48px;
}
.finance-submit-form>div{
background: #FFFFFF;
border: 1px solid #E8EBEF;
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
border-radius: 8px;
}


.finance-form-section h4{
    font-family: 'Soleil';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #55606F;  
    flex: none;
    order: 0;
    flex-grow: 0;
     margin: 21px 0px 9px 0px;
     
}

.finance-submit-form button {
    width: 100%;
    height: 100%;
    background: #00C5B3;
    border-radius: 8px;
    color: white;
    margin-top: 12px;
    border: none;
    cursor: pointer;

}

.finance-submitted-img {
    margin: 80px auto;
    box-sizing: border-box;
   

}
.finance-submitted-img  img{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
   

}


.credit-form-interest h4{
    font-family: 'Soleil';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: grid;
    align-items: center;
    color: #55606F;  
    flex: none;
    order: 0;
    margin:10px auto;
    flex-grow: 0;

}
.credit-form-interest>div{
    display: grid;
    gap: 10px;
    width: 90%;
    height: auto;
    margin: auto;
}
.credit-form-interest>div button{
   width: 100%;
   height: 40px;
   border-radius: 8px;
   border: none;
   color: white;
   background-color: teal;
   cursor: pointer;
}

.text-color-red{
    color: red;
    font-family: 'Soleil';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    flex: none;
}